import React from "react";
import { colors } from "@puzzle/theme";

export const CircledCheck = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="54" viewBox="0 0 55 54" fill="none">
      <circle
        cx="27.5"
        cy="27"
        r="25.5"
        stroke="url(#paint0_linear_1968_474)"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 10"
      />
      <path
        d="M36.1036 18.5622L23.4918 31.1657L18.8964 26.5733C18.5184 26.2085 18.0122 26.0066 17.4867 26.0112C16.9612 26.0158 16.4586 26.2264 16.087 26.5977C15.7154 26.9691 15.5046 27.4714 15.5001 27.9965C15.4955 28.5216 15.6975 29.0275 16.0626 29.4053L22.0749 35.4136C22.4507 35.7891 22.9604 36 23.4918 36C24.0232 36 24.5329 35.7891 24.9087 35.4136L38.9374 21.3941C39.3025 21.0164 39.5045 20.5105 39.4999 19.9854C39.4954 19.4603 39.2846 18.9579 38.913 18.5866C38.5414 18.2153 38.0388 18.0046 37.5133 18.0001C36.9878 17.9955 36.4816 18.1974 36.1036 18.5622Z"
        fill="url(#paint1_linear_1968_474)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1968_474"
          x1="7"
          y1="7.5"
          x2="49"
          y2="47.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.purple600} />
          <stop offset="1" stopColor={colors.green300} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1968_474"
          x1="36.5"
          y1="12.5"
          x2="36.5"
          y2="34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.purple600} />
          <stop offset="1" stopColor={colors.greenalpha} />
        </linearGradient>
      </defs>
    </svg>
  );
};
