import React, { useCallback, useMemo, useState } from "react";

import { AutocompleteInline, Menu } from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import { CaretDown } from "@puzzle/icons";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import Analytics from "lib/analytics/analytics";
import { useSize } from "react-use";
import { InactiveCompanyFragment } from "components/companies/graphql.generated";
import { useRouter } from "next/router";
import { isDynamicRoute, getClosestStaticRoute, Route } from "lib/routes";
import useSelf from "components/users/useSelf";
import Link from "next/link";
import { useAppRouter } from "lib/useAppRouter";

const Button = styled("button", {
  border: "1px solid transparent",

  appearance: "none",
  background: "none",
  width: "100%",
  padding: "$1h",
  fontWeight: "$bold",
  fontSize: "$body",
  lineHeight: "16px",
  letterSpacing: "$bodyXS",
  color: "$gray300",
  cursor: "pointer",
  userSelect: "none",
  outline: "none",
  textAlign: "left",
  whiteSpace: "nowrap",
  overflow: "hidden",

  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "$1",

  "&:hover, &:focus": {
    color: "$gray200",
  },

  "& > *:first-child": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    minWidth: "0",
  },

  "&:disabled": {
    pointerEvents: "none",
  },

  "&:not(:disabled)": {
    borderColor: "$gray600",
    borderRadius: "40px",
  },
});

export const CompanyMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { companies, company, setActiveCompanyId } = useActiveCompany<true>();
  const hasManyCompanies = companies.length > 1;
  const router = useRouter();
  const { isFirmRoute } = useAppRouter();

  const setCompany = useCallback(
    (company: InactiveCompanyFragment) => {
      Analytics.companyChanged({
        companyId: company.id,
      });

      setActiveCompanyId(company.id);
      setIsOpen(false);

      if (isDynamicRoute(router.pathname)) {
        router.replace(getClosestStaticRoute(router.pathname));
      }
    },
    [setActiveCompanyId, router]
  );

  const sortedCompanies = useMemo(() => {
    return [...companies].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, [companies]);

  const onAdmin = isFirmRoute(router.asPath);
  const { self } = useSelf();
  const showAdminPortalLink = self?.firmMembership?.isOwner;
  const allowSelect = showAdminPortalLink || hasManyCompanies;
  const [trigger, { width }] = useSize(
    <Button disabled={!allowSelect}>
      <span>{onAdmin ? "Admin portal" : company.name}</span>
      {allowSelect && <CaretDown />}
    </Button>
  );

  if (!allowSelect) {
    return trigger;
  }
  const widthOfClearButton = 42;
  return (
    <Menu
      trigger={trigger}
      open={isOpen}
      onOpenChange={(o) => {
        setIsOpen(o);
      }}
      css={{ width: width - widthOfClearButton }}
    >
      <AutocompleteInline<InactiveCompanyFragment, false, true, false>
        open={isOpen}
        options={sortedCompanies}
        getOptionKey={(o) => o.id}
        getOptionLabel={(o) => o.name || ""}
        value={onAdmin ? undefined : company}
        isOptionEqualToValue={(a, b) => {
          // give user experience of not having a company selected
          // when in the firm portal which is not specific to a company
          return a.id === b.id && !onAdmin;
        }}
        onChange={(_, value) => {
          if (value) setCompany(value);
        }}
        css={{ width: width - widthOfClearButton }}
      />
      {showAdminPortalLink && (
        <>
          <Menu.Separator />
          <Menu.Group>
            <Link href={Route.firmBookkeepers}>
              <Menu.Item
                aria-selected={onAdmin}
                css={{
                  '&[aria-selected="true"]': {
                    color: "$neutral100 !important",
                    backgroundColor: "$mauve700",
                  },
                }}
              >
                Admin portal
              </Menu.Item>
            </Link>
          </Menu.Group>
        </>
      )}
    </Menu>
  );
};
