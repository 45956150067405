import React from "react";
import { colors, styled } from "@puzzle/theme";

import { DATA_TEST_IDS } from "./constants";

const SlideIn = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  transition: "all 0.15s ease-in-out",
  transform: "translateX(-100%)",
  opacity: "0",

  defaultVariants: {
    minimized: false,
  },
  variants: {
    minimized: {
      false: {
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
      },
      true: {
        width: "40px",
        opacity: "1",
        transform: "translateX(0)",
      },
    },
  },

  // ${(p) =>
  //   !p.minimized &&
  //   css`
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     bottom: 0;
  //   `}

  // ${(p) =>
  //   (p.minimized || p.active) &&
  //   css`
  //     width: 40px;
  //     opacity: 1;
  //     transform: translateX(0);
  //   `}
});

const MinimizeButton = styled("button", {
  width: "32px",
  height: "32px",
  border: "none",
  cursor: "pointer",
  borderRadius: "$1",
  outline: "none",
  background: "none",
  color: "$gray300",

  "&:active, &:hover": {
    backgroundColor: colors.mauve700,
    color: "$neutral100",
  },
});

const MinimizerWrap = styled("div", {
  height: "50px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",

  defaultVariants: {
    minimized: false,
  },
  variants: {
    minimized: {
      true: {
        width: "40px",
        [`& ${MinimizeButton}`]: {
          width: "100%",
        },
      },
    },
  },
});

export const Minimizer = ({
  minimized,
  onToggle,
  hovered,
  ...props
}: {
  minimized: boolean;
  onToggle: (minimize: boolean) => void;
  hovered: boolean;
}) => {
  return (
    <MinimizerWrap {...props} data-testid={DATA_TEST_IDS.MINIMIZER}>
      <SlideIn minimized={minimized || hovered}>
        <MinimizeButton onClick={() => onToggle(!minimized)}>
          {minimized && (hovered ? <MinimizeIcon flip /> : <HamburgerIcon />)}
          {!minimized && <MinimizeIcon />}
        </MinimizeButton>
      </SlideIn>

      {!minimized && (
        <Logo showWordmark={!hovered} data-testid={DATA_TEST_IDS.LOGO}>
          <LogoSymbol />
          <Wordmark />
        </Logo>
      )}
    </MinimizerWrap>
  );
};

const Logo = styled("div", {
  display: "flex",
  alignItems: "center",
  transition: "transform 0.15s ease-in-out",

  "& > *:last-child": {
    marginLeft: "$2",
    transition: "opacity 0.15s ease-in-out",
  },

  defaultVariants: {
    showWordmark: true,
  },
  variants: {
    showWordmark: {
      false: {
        transform: "translateX(60px)",
        "& > *:last-child": {
          opacity: "0",
        },
      },
    },
  },
});

const MinimizeIcon = ({ flip }: { flip?: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      style={{ transform: flip ? "rotate(180deg)" : "none" }}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M6.5 4l-4 4 4 4M12.5 4l-4 4 4 4"
      ></path>
    </svg>
  );
};

const HamburgerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 3h14M1 8h14M1 13h9"
      ></path>
    </svg>
  );
};

export const LogoSymbol = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <path
        fill={colors.greenalpha}
        d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
      ></path>
      <path
        fill={colors.white}
        d="M13.6 6.398l-4.8 4.8h4.8v4.8l-4.8 4.8h4.8v4.8l4.8-4.8h-4.8v-4.8l4.8-4.8h-4.8v-4.8z"
      ></path>
      <path
        fill={colors.mauve800}
        fillRule="evenodd"
        d="M18.4 20.798a7.2 7.2 0 100-14.4h-4.8v4.8H8.8v4.8h4.8v4.8H8.8v4.8h4.8v-4.8h4.8zm0-4.8v-4.8h-4.8v4.8h4.8z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const Wordmark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="15" fill="none" viewBox="0 0 60 15">
      <path
        fill={colors.white}
        fillRule="evenodd"
        d="M58.164 13.213c-.801.654-1.895.986-3.282.986-1.611 0-2.88-.478-3.808-1.445-.928-.967-1.387-2.314-1.387-4.053 0-1.728.459-3.086 1.387-4.053.928-.966 2.197-1.445 3.808-1.445 1.534 0 2.774.488 3.702 1.475.927.986 1.396 2.265 1.396 3.828L59.882 9.6h-7.295c.244 1.396 1.006 2.1 2.295 2.1.508 0 .899-.079 1.162-.245.211-.125.416-.312.604-.557h3.079a4.807 4.807 0 01-1.563 2.315zm-3.282-7.51c-1.172 0-1.933.596-2.295 1.797h4.493a2.31 2.31 0 00-.723-1.28c-.38-.341-.879-.517-1.475-.517z"
        clipRule="evenodd"
      ></path>
      <path fill={colors.white} d="M44.687 0h2.756l.047 14.004h-2.803V0z"></path>
      <path
        fill={colors.white}
        fillRule="evenodd"
        d="M3.008 9.6v4.404H0V0h5.8c1.436 0 2.618.45 3.536 1.348.908.908 1.367 2.06 1.367 3.457 0 1.396-.459 2.549-1.367 3.447C8.418 9.15 7.236 9.6 5.8 9.6H3.008zm2.5-6.895h-2.5v4.2h2.5c1.377 0 2.197-.801 2.197-2.1 0-1.3-.82-2.1-2.197-2.1z"
        clipRule="evenodd"
      ></path>
      <path
        fill={colors.white}
        d="M32.577 14.003v-2.5h-5l4.902-5.996v-2.1h-8.3v2.5h4.697l-4.903 5.996v2.1h8.604zM42.581 14.003v-2.5h-5l4.903-5.996v-2.1h-8.301v2.5h4.697l-4.902 5.996v2.1h8.603zM17.097 11.201c1.032-.002 1.998-.798 1.998-1.685V3.36h2.802v6.153l-.003.003c-.044 1.337-.492 2.445-1.344 3.316-.899.908-2.051 1.367-3.448 1.367h-.01c-1.397 0-2.549-.459-3.447-1.367-.853-.87-1.3-1.979-1.345-3.316l-.003-.003V3.36l2.803.001v6.156c0 .887.966 1.684 1.997 1.685z"
      ></path>
    </svg>
  );
};
