import React, { useMemo } from "react";

import { Clear, Message } from "@puzzle/icons";
import { colors } from "@puzzle/theme";

import { Button, UnstyledButton, Text } from "ve";

import links from "lib/links";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { BookkeeperPlan, UserPosition, CoaType } from "graphql/types";
import { useUpsertMessageSettingsMutation } from "components/users/graphql.generated";
import useSelf from "components/users/useSelf";

import { useGetUserRolesForCompanyQuery } from "components/dashboard/Users/graphql.generated";

import { CircledCheck } from "./CircledCheck";

import {
  rootStyleLarge,
  buttonRowStyle,
  buttonInsideStyle,
  smallRootStyle,
} from "./NeedHelpBanner.css";

const LARGE_VARIANT_DISMISSED = "monthly-checklist-hirebookkeeper";
const SIDE_VARIANT_DISMISSED = "monthly-checklist-hirebookkeeper-side";

const HireAnExpertButton = ({ fullWidth }: { fullWidth: boolean }) => {
  const [upsertMessageSettings] = useUpsertMessageSettingsMutation();

  return (
    <Button
      variant="primary"
      shape="pill"
      fullWidth={fullWidth}
      onClick={() => {
        window.open(links.findPartner, "_blank");
        upsertMessageSettings({
          variables: {
            input: {
              key: LARGE_VARIANT_DISMISSED,
              value: true,
            },
          },
        });
      }}
    >
      <div className={buttonInsideStyle}>
        <Message />
        Find a partner
      </div>
    </Button>
  );
};

export const useShowNeedHelpBanner = () => {
  const { company } = useActiveCompany<true>();
  const { messageSettings } = useSelf();

  const { data, loading } = useGetUserRolesForCompanyQuery({
    variables: {
      companyId: company?.id,
    },
  });

  const largeVariantDismissed = messageSettings?.[LARGE_VARIANT_DISMISSED];
  const smallVariantDismissed = messageSettings?.[SIDE_VARIANT_DISMISSED];

  const memberships = data?.company?.memberships;
  const isAccountantOrCFO =
    memberships && memberships.some((m) => m.position === UserPosition.OutsourcedAccountantOrCfo);

  const hasCustomCoaType =
    company?.coaType && [CoaType.Decimal, CoaType.Every, CoaType.Fondo].includes(company?.coaType);

  const hasDecimalCoaType = company?.coaType === CoaType.Decimal;

  const hasBookeeper = company.bookkeeperPlan === BookkeeperPlan.HasBookkeeper;

  /*

    Note: many of the values exported in `useShowNeedHelpBanner` have nothing to do
    with showing a "Need Help" banner... and this is terrible!
    However, there is a Q1 2024 deadline on this code and multiple engineers are working on it,
    so a refactor is out of the question.
    I believe the intention is to keep all these values together and a refactor is planned after release.

  */

  return useMemo(
    () => ({
      showSmallNeedHelpBanner:
        largeVariantDismissed && // Show small variant once large one has been dismissed...
        !smallVariantDismissed && // ...but not when small variant has been dismissed
        company?.bookkeeperPlan !== BookkeeperPlan.HasBookkeeper &&
        !loading &&
        !isAccountantOrCFO &&
        !hasCustomCoaType,
      showLargeNeedHelpBanner:
        !largeVariantDismissed && // Show large variant until it has been dismissed
        company?.bookkeeperPlan !== BookkeeperPlan.HasBookkeeper &&
        !loading &&
        !isAccountantOrCFO &&
        !hasCustomCoaType,
      showHireAnAccountant: !loading && !isAccountantOrCFO && !hasCustomCoaType,

      showChatWithUs: !loading && !hasDecimalCoaType,
      showAskAnAccountant: !loading && !hasBookeeper && !hasDecimalCoaType,
      showShareFeedback: !loading && !hasDecimalCoaType,
      showInboxBookeepingPlanFeedback: !loading && !hasDecimalCoaType,
      loading,
    }),
    [loading, messageSettings, company, data]
  );
};

export const NeedHelpBannerLarge = () => {
  const [upsertMessageSettings] = useUpsertMessageSettingsMutation();

  const handleClear = () => {
    upsertMessageSettings({
      variables: {
        input: {
          key: LARGE_VARIANT_DISMISSED,
          value: true,
        },
      },
    });
  };

  return (
    <div className={rootStyleLarge}>
      <UnstyledButton
        onClick={handleClear}
        css={{ position: "absolute", right: "20px", top: "20px", padding: "8px" }}
      >
        <Clear color={colors.rhino200} size={24} />
      </UnstyledButton>

      <CircledCheck />

      <Text variant="bodyL" color="white">
        Hire an accounting professional
      </Text>
      <Text variant="headingM" color="gray300">
        If you need bookkeeping and tax preparation support, we can match you with one of our
        trusted partners.
      </Text>
      <div className={buttonRowStyle}>
        <HireAnExpertButton fullWidth={false} />
        <Button variant="secondary" shape="pill" onClick={handleClear}>
          Ignore
        </Button>
      </div>
    </div>
  );
};

export const NeedHelpBannerSmall = () => {
  const [upsertMessageSettings] = useUpsertMessageSettingsMutation();

  const handleClear = () => {
    upsertMessageSettings({
      variables: {
        input: {
          key: SIDE_VARIANT_DISMISSED,
          value: true,
        },
      },
    });
  };

  return (
    <div className={smallRootStyle}>
      <UnstyledButton
        onClick={handleClear}
        css={{ position: "absolute", right: "12px", top: "12px", padding: "8px" }}
      >
        <Clear color={colors.rhino200} size={24} />
      </UnstyledButton>

      <CircledCheck />
      <Text variant="bodyL" color="white">
        Hire an accounting professional
      </Text>
      <Text variant="headingM" color="gray300">
        Puzzle partners with bookkeeping and tax experts to support a range of accounting needs.
      </Text>
      <HireAnExpertButton fullWidth={true} />
    </div>
  );
};
