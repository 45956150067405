import React from "react";

import { UserEducation, ChevronDown } from "@puzzle/icons";
import { Menu } from "@puzzle/ui";
import { Text, Button } from "ve";
import { S, colors } from "@puzzle/theme";

import Analytics from "lib/analytics/analytics";
import { Route } from "lib/routes";
import { useAppRouter } from "lib/useAppRouter";

export type EducationMenuProps = {
  feature: string;
  learnMore?: string;
  tutorial?: string;
  demoRoute?: Route;
};

export const EducationMenu = ({ feature, learnMore, tutorial, demoRoute }: EducationMenuProps) => {
  const { router } = useAppRouter();

  const menuItemProps = {
    css: {
      padding: S["0h"],
      borderRadius: S["0h"],
      "&:hover, &:focus, &:active": {
        backgroundColor: `${colors.white100} !important`,
      },
    },
  } as React.ComponentProps<typeof Menu.Item>;

  const textProps = {
    variant: "bodyS",
    color: "gray200",
    style: { paddingLeft: S["1"] },
  } as React.ComponentProps<typeof Text>;

  const analyticsEvent = {
    feature,
    location: "Help Icon",
  };

  const handleLearnMore = () => {
    window.open(learnMore, "_blank");
    Analytics.featureOnboardingLearnMoreClicked(analyticsEvent);
  };

  const handleDemo = () => {
    if (!demoRoute) {
      return;
    }

    router.push(demoRoute);
  };

  return (
    <div>
      <Menu
        css={{
          padding: S["0h"],
          borderRadius: S["1"],
          width: 146,
          border: `0.5px solid ${colors.white300}`,
        }}
        trigger={
          <Button
            variant="minimal"
            suffixElement={<ChevronDown size={11} gradient />}
            css={{
              paddingLeft: S["1"],
              backgroundColor: `inherit !important`,
            }}
          >
            <UserEducation style={{ display: "flex", alignItems: "center" }} />
          </Button>
        }
      >
        {learnMore && (
          <Menu.Item {...menuItemProps} onClick={handleLearnMore}>
            <Text {...textProps}>Learn more</Text>
          </Menu.Item>
        )}
        {demoRoute && (
          <Menu.Item {...menuItemProps} onClick={handleDemo}>
            <Text {...textProps}>{`${feature} demo`}</Text>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};
