/* eslint-disable @typescript-eslint/consistent-type-definitions */
export enum CmdkShortcutId {
  GO_TO_INBOX = "go-to-inbox",
  GO_TO_DASHBOARD = "go-to-dashboard",
  GO_TO_ACCURACY_REVIEW = "go-to-accuracy-review",
  GO_TO_DOWNLOADS = "go-to-downloads",
  GO_TO_ACCOUNTING = "go-to-accounting",
  GO_TO_GENERAL_LEDGER = "go-to-general-ledger",
  GO_TO_RECONCILIATIONS = "go-to-reconciliations",
  GO_TO_MANUAL_JOURNAL_ENTRIES = "go-to-manual-journal-entries",
  GO_TO_FIXED_ASSETS = "go-to-fixed-assets",
  GO_TO_TRANSACTIONS = "go-to-transactions",
  GO_TO_SPENDING = "go-to-spending",
  GO_TO_REVENUE = "go-to-revenue",
  GO_TO_INVOICES = "go-to-invoices",
  GO_TO_CUSTOMERS = "go-to-customers",
  GO_TO_AR_AGING = "go-to-ar-aging",
  GO_TO_PEOPLE = "go-to-people",
  GO_TO_INTEGRATIONS = "go-to-integrations",
  GO_TO_COMPANY_SETTINGS = "go-to-company-settings",
  GO_TO_USER_SETTINGS = "go-to-user-settings",
  GO_TO_BILLS = "go-to-bills",
  GO_TO_VENDORS = "go-to-vendors",
  GO_TO_PREPAID_EXPENSES = "go-to-prepaid-expenses",
  AR_AGING = "ar-aging",
  GO_TO_BILLING_PLANS = "go-to-billing-plans",
  GO_TO_NOTIFICATION_SETTINGS = "go-to-notification-settings",
  GO_TO_RULES = "go-to-rules",
  GO_TO_AUTOMATION_SETTINGS = "go-to-automation-settings",
  GO_TO_POLICY_SETTINGS = "go-to-policy-settings",
  GO_TO_CLASSIFICATIONS = "go-to-classifications",
  GO_TO_AUDIT_LOG = "go-to-audit-log",
  GO_TO_LOCKED_PERIOD = "go-to-locked-period",
  GO_TO_CHART_OF_ACCOUNTS = "go-to-chart-of-accounts",
  GO_TO_ACCOUNTING_SETTINGS = "go-to-accounting-settings",
  GO_TO_HISTORICAL_BOOKS = "go-to-historical-books",
  KEYBOARD_SHORTCUTS = "keyboard-shortcuts",
  ASK_AN_ACCOUNTANT = "ask-an-accountant",
  HELP_DOCS = "help-docs",
  SEND_FEEDBACK = "send-feedback",
  CONTACT = "contact",
  LOGOUT = "logout",
  CMDK_MODAL = "cmdk-modal",
  GO_BACK = "go-back",
  OPEN_SEARCH = "open-search",
  PREVIOUS = "previous",
  NEXT = "next",
  SET_OR_CHANGE_CATEGORY = "set-or-change-category",
  SHOW_LAUNCHPAD = "show-launchpad",
}

export enum OnSelectActions {
  OPEN_SHORTCUTS = "OPEN_SHORTCUTS",
  OPEN_HELP_WINDOW = "OPEN_HELP_WINDOW",
  SHOW_INTERCOM = "SHOW_INTERCOM",
  TOGGLE_CMDK_MODAL = "TOGGLE_CMDK_MODAL",
  GO_TO_RULES = "GO_TO_RULES",
  GO_TO_LOCKED_PERIOD = "GO_TO_LOCKED_PERIOD",
  GO_TO_CHART_OF_ACCOUNTS = "GO_TO_CHART_OF_ACCOUNTS",
  LOGOUT = "LOGOUT",
  OPEN_BULK_RECATEGORIZE_MODAL = "OPEN_BULK_RECATEGORIZE_MODAL",
  SHOW_LAUNCHPAD_OVERLAY = "SHOW_LAUNCHPAD_OVERLAY",
}

export type ActionDict = Partial<Record<OnSelectActions, (e?: KeyboardEvent) => void>>;

export enum TargetType {
  TRANSACTION_ROW = "TRANSACTION_ROW",
}

export enum CmdkEvent {
  KEYPRESS = "keypress",
  CLICK = "click",
}

export interface Shortcut {
  description: string;
  icon: React.ReactNode;

  // OPTIONAL PROPERTIES HANDLING WHAT TO DO WHEN SHORTCUT IS SELECTED
  actionOnSelect?: string;
  route?: string;

  // OPTIONAL VALIDITY AND SEARCH PROPERTIES
  targetType?: TargetType; // for shortcuts that are specific to a type of element on the page, if not provided, the shortcut will work anywhere on the page
  urlPattern?: RegExp; // only shortcuts that match the current URL will be allowed, if no urlPattern is provided it is always allowed
  keywords?: string[]; // for searching shortcuts

  // OPTIONAL HOTKEYS PROPERTIES FOR THIS SHORTCUT
  hotkey?: {
    keys: string[]; // for UI display only. e.g. "[G] then [M]" The actual hotkeys for tinykeys are in hotkeyTrigger.
    trigger?: string; // the hotkey string for tinykeys that will trigger the shortcut - Optional because some hotkeys are handled on an individual page or component
    shouldDisableOnInputs: boolean; // TRUE = disable hotkey when focused on an input field. FALSE = always allow hotkey.
  };
}

export interface ShortcutWithStaticId extends Shortcut {
  id: CmdkShortcutId;
}

export interface ShortcutWithDynamicId extends Shortcut {
  id: string;
}

export interface ShortcutsGroup {
  title: string;
  id: string;
  urlPattern?: RegExp; // for bringing matching shortcuts groups to the top that match the urlPattern
  shortcuts: ShortcutWithStaticId[] | ShortcutWithDynamicId[];
}
